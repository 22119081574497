'use strict'
import '../../styles/blocks/_closing-action.scss'

export const playClosingActionLottie = () => {
    const pageHero = $('.js-closing-action')

    if(!$('#lottie-closing-action').length > 0)
        return false;


    if(pageHero && pageHero.length > 0){
        // This property allows us to know which assets to load, based on provided value
        const lottieToLoad = 'closing-action'

        if(lottieToLoad){
            // If the block has selected an illustration, load the animation data and check if it exists
            import(/* webpackChunkName: "lottie-data" */`../lottie-animations/${lottieToLoad}`).then(module => {
                let animationData = module.getData()
                let lottieOptions = module.getOptions(animationData)

                if (animationData !== 'undefined') {
                    // If the animation data exists, load the lottie library and play the animation
                    import(/* webpackChunkName: "hero-lottie-animation" */'lottie-web/build/player/lottie_svg.min.js').then(module => {
                        const anim = module.loadAnimation(lottieOptions);
                    })
                }
            })
        }
    }
}



$(window).on('load', function () {

    let $lottieContainer = $('.js-closing-action')

    if ($lottieContainer.length > 0) {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                const intersecting = entry.isIntersecting
                if (intersecting) {

                    playClosingActionLottie()

                    observer.unobserve(entry.target)
                }
            })
        })

        observer.observe($lottieContainer[0])
    }
});


